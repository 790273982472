<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="jade"
          itemProps="Home / Soluções / Jade"
          icon="icon-jade-color-48"
          title="Jade - Sistema de Gestão ao Vivo"
        />
        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-7">
                <!--  <h2 class="title-h2 title-outline"> -->
                <h2 class="h2">O que é um Live Management System?</h2>

                <div class="paragraph">
                  <p>
                    Um novo modelo de sistemas de informação se faz necessário
                    na indústria 4.0. Os sistemas do tipo EAM (Enterprise Asset
                    Management), YMS (Yard Management System) e LMS (Labor
                    Management System) têm se fundido, tornando impossível a
                    dissociação das informações e processos a eles integrados.
                  </p>
                  <p>
                    Com o advento de novas tecnologias de IoT (Internet of
                    Things), tais como,
                    <a @click="$router.push({ name: 'Garnet' })"
                      >Beacons e RFID</a
                    >
                    auxiliando na rastreabilidade e embarcados cada vez mais
                    baratos, um mundo de possibilidades de novas tecnologias e
                    soluções mais ágeis se torna possível.
                  </p>
                  <p>
                    Para isso o Jade-LiMS (Live Management System) vem
                    consolidar o monitoramento ao vivo dos principais
                    componentes do seu processo, desde os recursos da produção,
                    máquinas, ferramentas, até mesmo os funcionários. Tudo isso
                    garantido através de inteligência artificial para uma maior
                    predição da vida dos ativos, o controle e segurança do
                    processo.
                  </p>
                </div>
              </div>
              <CardContato
                callToAction="Garanta a segurança e controle dos seus processos com a ajuda da Shinier!"
                callBtn="quero esse módulo"
                gradient="jade-gradient-card"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- <h2 class="title-h2"> -->
                    <h2>
                      <strong>Como ele funciona?</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      A solução de um Sistema de Gestão ao Vivo permite a
                      sincronização dos principais processos previstos. Dentro
                      do planejamento do tráfego de carga e descarga, os
                      recursos são agendados e janelas de tempo atribuídas,
                      indicadores mensuráveis de aumentos da eficiência
                      consideram a histórica utilização de recursos melhorados,
                      durações de rendimento mais baixos e tempos de espera mais
                      curtos para veículos, a transparência obtida coloca o
                      usuário em uma posição para reconhecer desvios no início e
                      para iniciar as contramedidas necessárias rapidamente.
                    </p>
                    <p>
                      Ao implementar o gerenciamento de mão de obra a essa
                      solução, amplia-se o controle das ferramentas corporativas
                      que ajudam as empresas a planejar melhor seu trabalho
                      diário e processos, para melhor entrega de produtos e
                      serviços, facilitando o "relatório de produtividade do
                      trabalho" e a análise de unidades de trabalho, assim
                      também ajustando as unidades de tempo para permitir o
                      rastreamento de mudanças.
                    </p>
                    <p>
                      Com uma gestão eficiente do controle do ciclo de vida de
                      todos os seus ativos físicos e pessoas, você maximizará a
                      eficiência e valor. O gerenciamento de ativos envolve o
                      controle de construções físicas, comissionamento,
                      operação, manutenção, ativação e desativação de
                      instalações e equipamentos de forma eficaz, sendo possível
                      reduzir os custos de capital e os custos operacionais,
                      aumentar a vida útil dos ativos e, consequentemente, o
                      retorno dos ativos ROA (Return on Assets).
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="info">
                  <h2><strong> Por que unir os três sistemas? </strong></h2>
                  <figure
                    class="figure"
                    style="float: right; margin-left: 60px"
                  >
                    <img
                      v-lazy="image[1].src"
                      :alt="image[1].alt"
                      class="img-responsive img-loaded"
                    />
                  </figure>
                  <p>
                    Primeiramente nem todo negócio solicita todas as
                    implementações dos três sistemas, ou até mesmo a completa de
                    um, mas podem usar um módulo de cada solução. É comum então
                    no processo de implantação de um sistema a customização
                    exclusiva ao cliente, o que faz com que um projeto leve mais
                    tempo de implantação, ou seja, fique mais caro. Com as
                    diversas experiências da equipe da Shinier chegamos a uma
                    solução modular de alta aderência, eficiente e inovadora.
                    Com um modelo de dados genérico e descentralizado, é
                    possível rapidamente ter a análise desejada para apoiar
                    decisões das gerências dos mais diversos setores.
                  </p>
                  <p>Entre as principais funções incorporadas podemos citar:</p>
                  <ul style="list-style: inside">
                    <li>
                      Gestão dos processos livremente configurável para controle
                      flexível de tarefas;
                    </li>
                    <li>
                      Planejamento e reserva de recursos e janelas de tempo
                      (slots);
                    </li>
                    <li>
                      Registrar o tempo real de realização das tarefas e
                      compará-lo com o tempo padrão estimado para cada uma
                      delas;
                    </li>
                    <li>
                      Total transparência sobre a localização dos ativos através
                      de IoT, permitindo inventário automatizado, on-line e
                      registro histórico;
                    </li>
                    <li>
                      Gerar relatórios por departamento ao qual pertence o
                      operador, turno de trabalho, tipo de usuário por
                      produtividade e eficiência, tipos de processo realizado,
                      período de tempo, inclusive em termos diários.
                    </li>
                    <li>
                      Gerenciamento do ciclo de vida do ativo preditivo e
                      agendado, das entradas, movimentações, manutenções e
                      saídas dos bens corpóreos e incorpóreos, baseado em tempo,
                      prioridade e capacidades;
                    </li>
                    <li>
                      Integração com demais sistemas legados de chão de fábrica
                      e administrativos, ou qualquer outra solução da Shinier;
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section section-white section-beneficios">
              <div class="container">
                <div class="block-beneficios">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão de Ativos</strong></h2>
                        <figure
                          class="figure"
                          style="float: left; margin-right: 60px"
                        >
                          <img
                            v-lazy="image[0].src"
                            :alt="image[0].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          Gerencie suas ferramentas e maquinários, planeje
                          trocas, manutenções e compras. Integre todas as
                          atividades às ferramentas e tenha medidas em tempo
                          real dos processos dentro de sua fazenda. Através dos
                          dados coletados e gerenciados pelos sistemas, é gerada
                          uma eficiência produtiva, que reduz erros,
                          desperdícios e outros problemas que podem ser
                          prejudiciais ao meio ambiente.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios block-right">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>GO UP Produtor Rural</strong></h2>
                        <figure
                          class="figure"
                          style="float: right; margin-left: 60px"
                        >
                          <img
                            v-lazy="image[1].src"
                            :alt="image[1].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          Organize as atividades de campo, gerencie custos e
                          identifique oportunidades para expandir a produção. O
                          agricultor ou profissional agrícola pode ter um
                          registro conveniente de todos os produtos usados,
                          etapas de controle já realizadas, custos, previsões de
                          produção e todas as outras estatísticas importantes
                          para cada etapa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão de Relacionamento | CRM</strong></h2>
                        <figure
                          class="figure"
                          style="float: left; margin-right: 60px"
                        >
                          <img
                            v-lazy="image[2].src"
                            :alt="image[2].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          Fortaleça o relacionamento entre cooperativa e
                          produtor. Tenha informações consolidadas, acompanhe os
                          processos de cada produtor e aumente vendas pela
                          análise da carteira e classificação das propriedades.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios block-right">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão de Pessoas | HCM</strong></h2>
                        <figure
                          class="figure"
                          style="float: right; margin-left: 60px"
                        >
                          <img
                            v-lazy="image[3].src"
                            :alt="image[3].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          A Gestão de Talentos é o combustível que alimenta as
                          pessoas que dão vida à sua organização. Ganhe
                          eficiência e agilize os processos de RH.Gerencie
                          efetivamente a rotina do trabalho agrícola. Controle
                          horas trabalhadas e ponto de funcionários. Garanta
                          mais qualidade de vida para os colaboradores.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão de Frotas</strong></h2>
                        <figure
                          class="figure"
                          style="float: left; margin-right: 60px"
                        >
                          <img
                            v-lazy="image[4].src"
                            :alt="image[4].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          Gerencie o custo da frota em detalhes. Rastreie
                          caminhões, acompanhe datas de revisões, controle pneus
                          e reduza o custo do combustível. Mantenha sua frota
                          sempre em perfeitas condições para rodar, minimizando
                          riscos e otimizando ativos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios block-right">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão de Acesso e Segurança</strong></h2>
                        <figure
                          class="figure"
                          style="float: right; margin-left: 60px"
                        >
                          <img
                            v-lazy="image[5].src"
                            :alt="image[5].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          O controle de acesso é realizado por meio de barreiras
                          físicas (cercas, muros, portões, portas e etc),
                          vigilantes, cartão eletrônico, leitores, teclados,
                          dispositivos biométricos ou uma combinação de
                          tecnologias que restringem o acesso de pessoas,
                          materiais ou veículos a um determinado espaço. Proteja
                          seu patrimônio, integrando todas as rotinas de acesso
                          e segurança do seu negócio em um único sistema.
                          Monitore alarmes e imagens de onde estiver.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="block-beneficios">
                  <div class="beneficios-wrapper">
                    <div class="paragraph">
                      <div class="info">
                        <h2><strong>Gestão Empresarial | ERP</strong></h2>
                        <figure
                          class="figure small"
                          style="float: left; margin-right: 60px"
                        >
                          <img
                            v-lazy="image[6].src"
                            :alt="image[6].alt"
                            class="img-responsive img-loaded"
                          />
                        </figure>
                        <p>
                          Controle a qualidade de lotes de grãos com
                          classificação personalizada. Identifique o consumo de
                          matérias-primas. Classifique sementes e gerencie
                          rotinas fiscais, de modo a garantir maior eficiência,
                          integração e corrigir possíveis problemas, através de
                          um software de administração para seu agronegócio.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> A importância da gestão </strong></h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Avançar sobre as 5 forças de Porter é o desafio de todo
                      negócio, e com o monitoramento ao vivo do Jade-LiMS,
                      superar o poder de barganha dos fornecedores é possível
                      por meio da previsão precisa das demandas de compra dos
                      ativos e procura do melhor preço; a gestão preditiva e
                      automática também permite a sua equipe procurar novas
                      tendências e inovar, ganhando assim a vantagem sobre o
                      poder de barganha entre a concorrência, novos entrantes e
                      novos produtos.
                    </p>
                    <p>
                      Otimizar o desempenho dos funcionários e a utilização dos
                      ativos reduz custos operacionais e elimina o tempo de
                      inatividade não planejado. A implantação de uma solução
                      LiMS agrega valor e maximiza a vida útil de todos os tipos
                      de ativos – produção, instalações, transporte e TI.
                      Permite que sua organização desenvolva planos robustos
                      para calibração de instrumentos, além de programas de
                      manutenção de ativos (preventiva, preditiva e corretiva),
                      melhorando a eficácia diária das operações e da equipe
                      técnica, melhorando o produto final entregue ao seu
                      cliente, superando o último poder de barganha.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> O papel da tecnologia </strong></h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Como você já deve saber, a gestão de ativos não é uma
                      tarefa das mais simples: decidir pela necessidade de
                      aquisição do bem, armazená-lo em condições ideais,
                      capacitar colaboradores para operá-lo e controlar o
                      consumo de materiais.
                    </p>
                    <p>
                      A solução também melhora o gerenciamento dos equipamentos
                      e instalações para aumentar sua confiabilidade e garantir
                      a conformidade normativa com padrões como
                      <a
                        href="http://www.abs-qe.com/pt/sistema-de-gestao-de-ativos.html"
                        target="_blank"
                        >ISO 50001, ISO 55000 e ISO 17025</a
                      >, leis, regulamentos e requisitos específicos de cada
                      segmento, tudo isso reduzindo o uso de energia e apoiando
                      iniciativas de sustentabilidade.
                    </p>
                    <p>
                      Tudo isso fica mais fácil com apoio de sensores como
                      <a @click="$router.push({ name: 'Garnet' })"
                        >Etiquetas RFID ou Beacons</a
                      >
                      que são lidos por coletores móveis ou celulares; ou se
                      comunicam ativamente por antenas de rádio frequência ou
                      embarcados, com o mapeamento da empresa da planta e
                      recursos espalhados estrategicamente, a confiabilidade do
                      rastreio é garantida pelos algoritmos de inteligência
                      artificial. Conheça mais sobre nossa solução Jade-LiMS
                      requisitando um consultor da
                      <a @click="openModal">Shinier</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->

        <!--  -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[11].src"
                :alt="image[11].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">AcquaNativa</span>
                  <hr />
                </div>
                <p>
                  Sediada em São Carlos - SP, a AcquaNativa atua há mais de 20
                  anos, e é especializada no desenvolvimento de soluções para o
                  meio ambiente, tendo dentre seus serviços o aluguel de
                  equipamentos, consultoria ambiental e análises laboratoriais
                  de água e efluentes industriais e para os principais
                  indicadores de fertilização e contaminação do solo. A empresa
                  nos procurou para agregar o compliance de uma plataforma web,
                  aplicativo e motores dos servidores melhorando assim a
                  escalabilidade e experiência para seus clientes.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";

export default {
  name: "Jade",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Jade - Monitoramento ao Vivo de Assets",
    meta: [
      {
        name: "description",
        content:
          " Um novo modelo de sistemas de informação se faz necessário na indústria 4.0. Os sistemas do tipo EAM (Enterprise Asset Management), YMS (Yard Management System) e LMS (Labor Management System) tem se fundido sendo impossível a dissociação das informações e processos a eles integrados."
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesJade;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
