<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="diamond"
          itemProps="Home / Módulos / Diamond"
          icon="icon-diamond-color-48"
          title="Diamond - Marketplaces"
        />

        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-7">
                <!-- title-h2 title-outline -->
                <h2 class="h2">Por que devo investir em um marketplace?</h2>

                <div class="paragraph">
                  <p>
                    Com o crescente uso da tecnologia e a popularização do
                    comércio eletrônico, os marketplaces têm se tornado uma
                    alternativa atraente para os consumidores, pois oferecem um
                    amplo leque de opções em um só lugar. Além disso, os
                    marketplaces permitem que as empresas reduzam seus custos
                    operacionais e alcancem uma base de clientes maior e mais
                    diversificada. Isso ocorre porque a presença em um
                    marketplace permite que as empresas tenham maior
                    visibilidade, o que pode levar a um aumento significativo
                    nas vendas e na receita.
                  </p>
                  <p>
                    Além disso, os marketplaces geralmente oferecem recursos de
                    marketing, como campanhas promocionais e anúncios
                    patrocinados, que podem ajudar a aumentar ainda mais a
                    visibilidade dos produtos e serviços das empresas. Por essas
                    razões, investir em um marketplace pode ser uma estratégia
                    eficaz para empresas que desejam expandir seus negócios e
                    alcançar novos mercados e consumidores.
                  </p>
                </div>
              </div>
              <CardContato
                callBtn="quero esse módulo"
                gradient="diamond-gradient-card"
                callToAction="Disponibilizamos planos para você ter seu marketplace hoje mesmo"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- class="title-h2" -->
                    <h2>
                      <strong>Vantagens de se ter um marketplace </strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Uma das principais vantagens de se ter um marketplace é a
                      visibilidade que a plataforma pode proporcionar. Ao se
                      unir a outras empresas, é possível alcançar novos clientes
                      e expandir a base de consumidores, uma vez que o
                      marketplace já possui um grande número de usuários
                      registrados. Além disso, o marketplace pode oferecer
                      diversas opções de marketing e publicidade para promover
                      os produtos e serviços das empresas, o que pode ajudar a
                      aumentar ainda mais a visibilidade da marca.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Custo benefício de se ter um marketplace </strong>
                    </h2>
                    <p>
                      Outra grande vantagem é a redução de custos. Ao vender
                      seus produtos e serviços em um marketplace, a empresa pode
                      economizar em diversos aspectos, como em investimentos em
                      marketing e publicidade, desenvolvimento e manutenção de
                      plataforma, e outros custos operacionais. Isso porque, ao
                      invés de arcar com esses custos sozinha, a empresa pode
                      dividi-los com outras empresas parceiras do marketplace,
                      reduzindo assim o investimento necessário.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong>Facilidade na gestão</strong></h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <!--  -->
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Outro ponto importante é a facilidade de gestão que um
                      marketplace pode proporcionar. A plataforma pode oferecer
                      recursos de gestão de estoque, pagamentos e logística,
                      simplificando o processo de vendas para as empresas
                      parceiras. Dessa forma, a empresa pode focar no
                      desenvolvimento e aprimoramento de seus produtos e
                      serviços, enquanto o marketplace se encarrega do
                      gerenciamento da plataforma.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Benefícios para o consumidor final</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Por fim, é importante destacar que um marketplace também
                      pode ser uma ótima opção para os consumidores, que
                      encontram em um único lugar uma grande variedade de
                      produtos e serviços. Além disso, a competição entre as
                      empresas no marketplace pode ajudar a manter preços mais
                      competitivos, oferecendo assim uma melhor relação
                      custo-benefício para os consumidores.
                    </p>
                    <p>
                      Em resumo, a criação de um marketplace pode ser uma
                      estratégia interessante para empresas que desejam expandir
                      sua presença no mercado e aumentar a visibilidade de seus
                      produtos e serviços. As vantagens incluem redução de
                      custos, facilidade de gestão, aumento da visibilidade da
                      marca e melhor experiência de compra para os consumidores.
                      Entre em contato com a equipe da <a>Shinier</a> para saber
                      mais.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>O que o módulo Diamond tem a oferecer?</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Customizamos uma plataforma para você, com as seguintes
                      funcionalidades:
                    </p>
                    <p></p>
                    <ul
                      class="list-top"
                      style="list-style: inside disc; text-align: justify"
                    >
                      <li>
                        Gestão de acesso multinível de usuários, auto cadastro,
                        supervisionado ou integrado: Com a crescente demanda por
                        plataformas digitais para a oferta de produtos e
                        serviços, a gestão de acesso multinível de usuários é
                        fundamental para garantir a segurança e privacidade dos
                        dados dos clientes. Essa gestão pode ser implementada
                        por meio de um sistema de autenticação de usuários, que
                        permite o cadastro de novos usuários de forma automática
                        ou supervisionada. Além disso, a integração de
                        diferentes níveis de acesso permite uma melhor
                        organização da plataforma, com diferentes permissões
                        para cada tipo de usuário.
                      </li>
                      <li>
                        Criação dinâmica de grupos, categorias, classes, que
                        facilita a organização de produtos e serviços dentro da
                        plataforma. Com essa ferramenta, é possível criar
                        diferentes tipos de grupos e categorias, que podem ser
                        facilmente ajustados e atualizados de acordo com as
                        necessidades da empresa e dos usuários.
                      </li>
                      <li>
                        Match de serviço/produto entre cliente e fornecedor, é
                        uma funcionalidade que oferece uma experiência
                        personalizada para o usuário. Por meio de um sistema de
                        inteligência artificial, é possível identificar as
                        preferências do usuário e recomendar serviços e produtos
                        que se adequem às suas necessidades. Além disso, a
                        central de controle para fornecedores permite que os
                        mesmos possam gerenciar seus serviços e produtos de
                        forma mais eficiente.
                      </li>
                      <li>
                        Sistemas de cobranças variados, que oferecem diferentes
                        opções de pagamento para os usuários, como ticket,
                        moedas personalizadas, comissão multinível, cartão
                        boleto e etc.
                      </li>
                      <li>
                        Com a customização e agregação de imagens e vídeos a
                        produtos/serviços, é possível criar uma experiência de
                        compra mais atraente e envolvente para o usuário.
                      </li>
                      <li>
                        Sistema de notificação por app, email e whatsapp: é
                        fundamental para manter os usuários informados sobre
                        novos serviços, atualizações e promoções dentro da
                        plataforma.
                      </li>
                      <li>
                        Publicação sem custo adicional para Play Store, Apple
                        Store, Windows 10, Mac e Linux, que amplia o alcance da
                        plataforma e possibilita a aquisição de novos usuários.
                      </li>
                      <li>
                        Sistema de geolocalização em tempo real, permitindo que
                        os usuários encontrem serviços e produtos mais próximos
                        de sua localização.
                      </li>
                      <li>
                        Gravação e streaming de vídeo, importantes para a oferta
                        de serviços e produtos audiovisuais.
                      </li>
                      <li>
                        API nativa e documentação para integração de parceiros,
                        que permite que empresas parceiras integrem seus
                        serviços e produtos na plataforma, ampliando ainda mais
                        a oferta de serviços e produtos para os usuários.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->

        <!--  -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[4].src"
                :alt="image[4].alt"
                class="img-loaded img-col-case"
              />

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Small doações</span>
                  <hr />
                </div>
                <p>
                  Os fundadores da Small Doações nos encontraram na fase inicial
                  da empresa e juntos traçamos os planos de MVP, e crescimento
                  do projeto, auxiliando a criação da start-up e tomamos a
                  responsabilidade de desenvolvimento de toda a tecnologia.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";
export default {
  name: "Diamond",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Diamond - Construa seu marketplace",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Diamond - Construa seu marketplace ,customizamos uma plataforma para você, com gestão de acesso, multi nível de usuários, auto cadastro, supervisionado ou integrado, e muito mais",
      },
    ],
  },
  data() {
    return {
      image: [],
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesDiamond;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato,
  },
};
</script>
<style scoped src="./solucoes.css"></style>