<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="ametista"
          itemProps="Home / Soluções / Ametista"
          icon="icon-ametista-color-48"
          title="Ametista - Software de Gestão Educacional"
        />

        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-7">
                <!-- title-h2 title-outline -->
                <h2 class="h2">Os desafios da educação na tecnologia</h2>

                <div class="paragraph">
                  <p>
                    Sabemos a importância que a educação tem na atual conjuntura
                    econômica, política e social do país, não é mesmo? Você,
                    como um profissional da área financeira ou da educação,
                    entende o papel que ela representa para o desenvolvimento de
                    nossa sociedade.
                  </p>
                  <p>
                    Entretanto, existem diversos aspectos que também são
                    importantes para que a educação aconteça da melhor maneira
                    possível. Um dos melhores instrumentos de análise e gestão é
                    escolher um bom Education Management System - EMS:
                  </p>

                  <p></p>
                  <ul
                    class="list-top"
                    style="list-style: inside disc; text-align: justify"
                  >
                    <li>Você sabe o que é gestão escolar?</li>
                    <li>
                      Entende seus benefícios, como lidar com a inadimplência e
                      como encontrar vantagens e oportunidades?
                    </li>
                  </ul>
                  <p></p>
                  <p>
                    Sabe como ter um sistema de gestão escolar e quais os
                    principais desafios? Pois é exatamente sobre estas questões
                    que refletiremos. Vamos lá?
                  </p>
                </div>
              </div>
              <CardContato
                :route="$route.name"
                callBtn="quero esse módulo"
                gradient="ametista-gradient-card"
                callToAction="Venha integrar seus processos e aumentar a eficiência e produtividade da sua escola!"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- title-h2 -->
                    <h2>
                      <strong>O que é um software gestão educacional?</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      O conceito de gestão escolar nos remete ao gerenciamento e
                      administração de uma determinada instituição de maneira
                      eficiente. Portanto, o objetivo da gestão escolar é
                      organizar todas as áreas, aspectos e determinantes para
                      que o processo fim (a educação) ocorra de maneira eficaz,
                      garantindo seu objetivo principal, que é a aprendizagem
                      efetiva por parte dos alunos.
                    </p>
                    <p>
                      Atualmente, diante de tantas possibilidades digitais e do
                      avanço tecnológico, os softwares de gestão escolar estão
                      em pauta. A automatização garante uma integração de todos
                      os fatores do processo educacional (tanto a atividade fim
                      como as atividades à sua volta) garantindo, assim, uma
                      gestão eficiente.
                    </p>
                    <p>
                      Mas, quais são os benefícios desse tipo de ferramenta?
                      Conheça a seguir
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Benefícios dos softwares de gestão escolar</strong
                      >
                    </h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Os benefícios ligados à utilização desse tipo de
                      ferramenta são diversos. E para começar, citaremos:
                    </p>
                    <p></p>
                    <ul style="list-style: inside">
                      <li>Aumento na captação de alunos;</li>
                      <li>Diminuição da evasão escolar;</li>
                      <li>Diminuição dos níveis de inadimplência;</li>
                      <li>Controle e gestão financeira eficiente;</li>
                      <li>Aumento da produtividade das equipes envolvidas;</li>
                      <li>
                        O foco passa a ser o cliente e não os processos
                        operacionais;
                      </li>
                      <li>
                        Melhoria na tomada de decisão das áreas gerenciais;
                      </li>
                      <li>Fidelização de clientes (pais e responsáveis).</li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Como lidar com a inadimplência no mercado</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Sabemos que a inadimplência é algo com que todas as
                      instituições precisam lidar. E, por poder prejudicar o
                      andamento de algumas atividades e projetos, é importante
                      preparar-se para ela. Mas, como?
                    </p>
                    <ul style="list-style: inside">
                      <li>
                        <b>Conheça os pais e o seu aluno: </b> a melhor maneira
                        é através de formulários e reuniões mensais. O software
                        auxilia no cadastramento dos estudantes!
                      </li>
                      <li>
                        <b>Tenha um departamento financeiro estruturado:</b>aqui
                        também entra o software de gestão, pois ele auxilia no
                        gerenciamento das finanças da escola, ajuda a saber quem
                        está inadimplente e acompanhar as datas de vencimento
                      </li>
                      <li><b>Cobrança automática;</b></li>
                      <li><b>Departamento jurídico preparado.</b></li>
                    </ul>
                    <p>
                      Em resumo, fica muito mais fácil lidar com a inadimplência
                      quando se tem um software de gestão escolar.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong>Vantagens e oportunidades</strong></h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Dentre as mais diversas vantagens e oportunidades, podemos
                      destacar:
                    </p>
                    <p></p>
                    <ul style="list-style: inside">
                      <li>Flexibilidade no controle da instituição;</li>
                      <li>
                        Segurança através de senhas com criptografia e dados
                        armazenados em um servidor;
                      </li>
                      <li>Necessidades e demandas reunidas em um só espaço;</li>
                      <li>
                        Possibilidade de análise de perfil dos pais e alunos;
                      </li>
                      <li>
                        Emissão de relatórios de inadimplência, recebimentos e
                        pagamentos.
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      Agora que entendemos o conceito de gestão escolar e os
                      benefícios e vantagens do software de gestão escolar,
                      conheceremos os principais existentes no Brasil.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>Como melhorar meu software educacional?</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[4].src"
                        :alt="image[4].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Muitas escolas e instituições já possuem algum tipo de
                      sistema escolar, seja para fazer matrículas, gestão de
                      boletos, ou acesso de atividades online, e migrar toda a
                      base existente e se desfazer do sistema antigo, pode gerar
                      um desgaste entre os colaboradores. Então o que fazer?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong>Software educacional integrado</strong></h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[5].src"
                        :alt="image[5].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      É aquele software que possui integração, via API, ou base
                      de dados, nosso software Ametista pode ser liberado apenas
                      os módulos necessários, para completar sua gestão
                      educacional, consolidando as informações de um sistema
                      para o outro automaticamente. E montaremos uma cartilha de
                      migração de um sistema para outro dentro do seu calendário
                      escolar de forma gradativa.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong>Módulos para software existentes</strong></h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[6].src"
                        :alt="image[6].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Entendendo a atual necessidades das organizações
                      educacionais, liberamos módulos da nossa API para que
                      softwares terceiros usem dentro de suas soluções, tais
                      como:
                    </p>
                    <p></p>
                    <ul style="list-style: inside">
                      <li>módulo de pagamento</li>
                      <li>gestão de materiais online;</li>
                      <li>stream de vídeo-aulas;</li>
                      <li>digitalização e assinatura de documentos.</li>
                    </ul>

                    <p></p>
                    <p>
                      Ametista é o software de gestão escolar completo, que vai
                      integrar todos os processos, aumentar a eficiência e a
                      produtividade de sua escola!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->

        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[7].src"
                :alt="image[7].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">VirtualRed </span>
                  <hr />
                </div>
                <p>
                  Plataforma de Cursos e Correção de Redações Online VirtualRed,
                  o curso Red nos procurou para desenvolvermos a plataforma web
                  o diferencial do seu curso de redações ainda em 2019, também
                  desenvolvemos um aplicativo e trabalhamos continuamente para
                  melhoria da plataforma.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";

export default {
  name: "Ametista",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Ametista - Software de Gestão Educacional",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "description",
        content:
          "É o software de gestão escolar completo, que vai integrar todos os processos, aumentar a eficiência e a produtividade de sua escola! Tem objetivo de organizar todas as áreas determinantes para que o processo fim (a educação) ocorra de maneira eficaz, garantindo seu objetivo principal, que é a aprendizagem efetiva por parte dos alunos"
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesAmetista;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
