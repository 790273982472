<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="garnet"
          itemProps="Home / Soluções / Garnet"
          icon="icon-garnet-color-48"
          title="Garnet - Tecnologia Bluetooth Low Energy (BLE)"
        />

        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-7">
                <!-- <h2 class="title-h2 title-outline"> -->
                <h2 class="h2">O que é Beacon ou BLE?</h2>

                <div class="paragraph">
                  <p>
                    Trata-se de um pequeno dispositivo que emite sinais por meio
                    da tecnologia Bluetooth Low Energy (BLE). Atualmente, eles
                    são muito utilizados em setores como varejo, saúde e
                    educação, principalmente nos Estados Unidos. Porém, os
                    beacons na indústria estão ganhando espaço rapidamente e já
                    começam a representar uma verdadeira revolução no setor.
                  </p>
                  <p>
                    Através desses sinais de BLE, é possível obter diversas
                    informações úteis ao processo produtivo como umidade,
                    vibração, temperatura, queda, entre outros. Integramos essa
                    fantástica tecnologia através do Módulo Garnet a qualquer
                    sistema de informação (SI). Com os beacons, um conjunto de
                    Internet of Things (IoT) e algoritmos de inteligência
                    artificial é possível determinar a localização precisa de um
                    objeto monitorado, em tempo real.
                  </p>
                  <p>
                    Por envolver áreas com grandes dimensões, as fábricas sempre
                    se preocuparam com temas como rastreabilidade. Foi nesse
                    sentido que surgiram as etiquetas RFID, mas o alcance
                    limitado, a interferência em metais e o custo de
                    investimento tornam essa tecnologia restrita. Já os beacons
                    trazem uma solução de mais fácil instalação e que sofre
                    menos interferência do ambiente na emissão de sinais.
                  </p>
                  <p>
                    O seu funcionamento é muito simples: basta espalhar sensores
                    receptores em locais estratégicos da planta e acoplar os
                    beacons em recursos que devem ser monitorados. Mas afinal,
                    que soluções são propostas ao realizar este rastreamento?
                  </p>
                </div>
              </div>
              <CardContato
                :route="$route.name"
                callToAction="Veja como a Shinier pode te ajudar a utilizar tecnologias de BLE e IoT a favor do seu negócio!"
                callBtn="quero esse módulo"
                gradient="garnet-gradient-card"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Gestão de Staff </strong></h2>
                    <figure
                      class="figure medium"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Ao usar um acessório (ex.: colete de segurança) com essa
                      tecnologia nos colaboradores, é possível saber em tempo
                      real onde está cada funcionário e entender a movimentação
                      da equipe durante o dia. Isso dá ao gestor uma
                      possibilidade incrível para otimização de processos
                      industriais, já que ele pode:
                    </p>
                    <ul style="list-style: inside">
                      <li>
                        Acompanhar possíveis problemas de conformidade ou
                        segurança (por exemplo, identificando se quem está
                        envolvido em uma tarefa específica é um trabalhador que
                        foi treinado para isso);
                      </li>
                      <li>
                        Automatizar registros para entender responsabilidades
                        (qual trabalhador entrou em contato com qual lote de
                        produto usando qual ferramenta);
                      </li>
                      <li>
                        Gerenciar e otimizar a força de trabalho (melhoria nas
                        rotas, otimização de viagens e do tempo gasto em cada
                        área da fábrica e realocação de pessoas);
                      </li>
                      <li>
                        Usar o próprio sistema como adicional ao ponto: entender
                        que horas determinado colaborador começou e terminou o
                        turno e evitar que ele fique no local além do que é
                        permitido pelas normas vigentes, garantindo também
                        cumprimentos trabalhistas envolvidos no artigo 66 da
                        CLT;
                      </li>
                      <li>
                        Outro ponto crucial desse monitoramento está na garantia
                        de segurança do trabalho, uma vez que conseguimos medir
                        o grau de exposição de um funcionário a riscos de
                        periculosidade / insalubridade e até mesmo de vida!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Gestão de Equipamentos </strong></h2>
                    <figure
                      class="figure medium"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Toda fábrica, fazenda ou montadora possui, além do
                      inventário, equipamentos e ferramentas de baixo ou alto
                      valor agregado. Dependendo do tamanho do pátio, é normal
                      perder ou não entender muito bem a disponibilidade de tais
                      itens na planta. Há casos de empresas com dificuldades de
                      encontrar empilhadeiras ou entender o nível de utilização
                      de pontes rolantes, por exemplo.
                    </p>
                    <p>
                      Ao instalar um beacon nesses equipamentos móveis, você
                      identifica quanto eles seguem uma rota não desejada e
                      identifica a localização caso isso aconteça. Além da
                      localização em si, um mundo de possibilidades de
                      otimização de processos e utilização destes equipamentos
                      se abre! Como principal exemplo temos o

                      <a @click="$router.push({ name: 'Jade' })">Jade - LMS</a>
                      um Sistema de Gestão ao Vivo para garantir o monitoramento
                      de movimentação e manuseio de todos os ativos, máquinas,
                      ferramentas e pessoas no seu negócio.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Controle de Inventário </strong></h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      É possível acoplar os beacons nos itens que você produz.
                      Seja no próprio produto, em pallets, lotes ou caixas, o
                      dispositivo conseguirá determinar a localização desse
                      ativo na fábrica. Dessa forma, a empresa evita:
                    </p>
                    <p></p>
                    <ul style="list-style: inside">
                      <li>
                        Perder o produto ou demorar para encontrá-lo: Imagine
                        que você trabalha em uma montadora e precisa enviar ao
                        cliente um carro específico em meio a centenas de outros
                        similares na fábrica. Se não souber exatamente onde está
                        o produto, você vai demorar para encontrá-lo e o pedido
                        deve atrasar.
                      </li>
                      <li>
                        Roubos e avarias: Se você tem itens ou matérias-primas
                        de alto valor agregado e eles somem na fábrica sem
                        maiores explicações, uma solução com beacons pode te
                        salvar, identificando em tempo real a localização destes
                        produtos e uma movimentação indevida.
                      </li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>Desafios para uso de beacons na indústria</b>
                    </h3>
                    <p>
                      Os beacons têm potencial para se popularizar nas
                      indústrias brasileiras e guiarem o Planejamento e Controle
                      de Produção, além de toda a gestão de processos produtivos
                      envolvendo pessoas, porém, poucos profissionais conhecem
                      essa tecnologia. O primeiro desafio, portanto, é provar o
                      seu valor e mostrar ao mercado que o RFID não é a única
                      opção para a rastreabilidade.
                    </p>
                    <p>
                      Para o futuro, podemos esperar que os algoritmos sejam
                      cada vez mais refinados e o nível de precisão dos sinais
                      emitidos pelos beacons sejam ainda mais precisos - hoje os
                      algoritmos da Shinier já conseguem localizar um sensor
                      beacon com até 1,5 metros de precisão - a ideia é que os
                      beacons, assim como qualquer outro dispositivo IoT, sejam
                      apenas uma parte da grande revolução que chamamos de
                      Indústria 4.0, mas uma parte fundamental serão os
                      geradores de dados confiáveis, constantes e em tempo real.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Tecnologia a seu alcance </strong></h2>
                    <figure
                      class="figure medium"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Engana-se quem pensa que essa tecnologia ainda está muito
                      longe de se tornar realidade ou é exclusiva para grandes
                      fábricas. Os beacons tem custos relativamente baixos e
                      diferentes tipos de empresas podem aproveitar-se dessa
                      inovação.
                    </p>
                    <p>
                      Hoje o mercado está bem amadurecido em soluções de
                      hardware e a maioria das empresas que se apoiam nessas
                      novas tecnologias tendem a prestar um serviço, sem
                      transferir o peso dos custos do hardware ao cliente final!
                    </p>
                    <p>
                      Uma boa gestão da cadeia de suprimentos é essencial para
                      melhorar a produtividade e potencializar o rendimento
                      mensal do seu negócio. Porém, há diversos desafios
                      envolvidos nesse setor.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Como a Shinier pode te ajudar?</strong></h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[4].src"
                        :alt="image[4].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Com foco em reduzir custos e revolucionar a operação de
                      nossos clientes, desenvolvemos o Módulo Garnet uma solução
                      de fácil integração, com sistemas legados, ou outros
                      <a @click="$router.push({ name: 'Solucoes' })"
                        >produtos da Shinier</a
                      >. Focando no entendimento e melhoria organizacional dos
                      processos operacionais, rastreando pessoas e suas
                      atividades, sozinhas ou no comando de equipamentos.
                    </p>
                    <p>
                      Através de um conjunto de tecnologias proprietárias que
                      envolvem Geolocalização indoor e outdoor (RTLS), Internet
                      das Coisas e Inteligência Artificial. Entregamos aos
                      nossos clientes um entendimento holístico destes
                      processos, permitindo a medição de dados confiáveis e o
                      controle integral – em tempo real – de diversos
                      indicadores críticos sobre sua operação. Quer saber mais
                      sobre nosso trabalho? entre em contato com a equipe da
                      <a
                        @click="
                          {
                          }
                        "
                        >Shinier</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong>Integração de Sistemas:</strong></h2>
                    <p>
                      A integração física ou funcional de vários sistemas de
                      computador e aplicativos de software para que funcionem
                      como um todo coordenado e permitam a troca de informações
                      entre diferentes sistemas. Isso dá às empresas uma visão
                      abrangente de seus negócios. Informações em tempo real
                      sobre o processo de produção podem influenciar rapidamente
                      as decisões de gerenciamento e facilitar as decisões
                      estratégicas sobre os negócios da empresa na planta de
                      produção. Não basta instalar um pacote ERP, mas integrar
                      com um sistema de controle de produção industrial sim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->
        <!--  -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[5].src"
                :alt="image[5].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Coss Consulting</span>
                  <hr />
                </div>
                <p>
                  A COSS é um provedor de soluções inteligentes baseado em
                  serviços digitais. Auxilia na criação de valor e transformação
                  dos negócios através do poder da inovação ao redor de
                  tecnologias disruptivas como EPC, RFID, NFC, Internet das
                  Coisas (IoT), Big-Data, AI & ML e Computação em Nuvem,
                  on-line.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";

export default {
  name: "Garnet",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Garnet - Como Aplicar IoT? - Tecnologia Bluetooth Low Energy",
    meta: [
      {
        name: "description",
        content:
          "Por meio da tecnologia Bluetooth Low Energy (BLE). Atualmente, eles são muito utilizados em setores como varejo, saúde e educação, principalmente nos Estados Unidos. Porém, os beacons na indústria estão ganhando espaço rapidamente e já começam a representar uma verdadeira revolução no setor."
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesGarnet;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
