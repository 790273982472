<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="quartz"
          itemProps="Home / Soluções / Quartz"
          icon="icon-quartz-color-48"
          title="Quartz - Serviço de Gestão de Aplicações"
        />
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-7">
                <!-- <h2 class="title-h2 title-outline"> -->
                <h2 class="h2">Por que contratar um serviço de AMS?</h2>

                <div class="paragraph">
                  <p>
                    Os serviços de AMS - Application Management Services -
                    cuidam da gestão e suporte de sistemas de tecnologia
                    implantados em sua empresa e são imprescindíveis para manter
                    a qualidade, atualização e performance das aplicações. Ter
                    uma boa equipe de sustentação, com processos bem definidos,
                    garante a detecção, diagnóstico e resolução de demandas com
                    rapidez e assertividade, além de manter a satisfação do
                    cliente de seu negócio
                  </p>
                  <p>
                    Tradicionalmente, as empresas mantêm equipes internas de
                    AMS, contudo, atualmente há uma tendência crescente de
                    terceirização deste serviço. Essa prática deve aumentar nos
                    próximos anos, pois reduz custos, aumenta a segurança contra
                    erros nas aplicações, permite a realocação do trabalhador já
                    contratado.
                  </p>
                  <p>
                    O Quartz trata-se de um serviço de suporte a sistemas que
                    deve ser ofertado com excelência e qualidade, agregando
                    valor de forma sustentável aos clientes.
                  </p>
                </div>
              </div>
              <CardContato
                :route="$route.name"
                callToAction="Veja como a Shinier pode te ajudar a utilizar tecnologias de ServiceDesk a favor do seu negócio!"
                callBtn="quero esse módulo"
                gradient="quartz-gradient-card"
              />
            </div>
          </div>
        </div>
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!--   <h2 class="title-h2"> -->
                    <h2>
                      <strong>
                        Entre as razões para investir no outsourcing da
                        sustentação de aplicações estão:
                      </strong>
                    </h2>

                    <p>
                      Há algumas as diferenças entre as modalidades de
                      manutenção: R&D (Research & Development), na qual são
                      atendidas as correção de bugs, P&D (Pesquisa e
                      Desenvolvimento) - de negócio, a evolução tecnológica dos
                      produtos desenvolvidos e, por fim, o contrato complementar
                      à manutenção, que trata a sustentação com nível de serviço
                      destes produtos atendidos por AMS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>1. Manter uma equipe alocada pode ser mais caro</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Montar uma equipe de colaboradores com expertise que
                      atenda multiaplicações pode ser mais custoso. Em alguns
                      casos, é preciso contratar um profissional para cada uma
                      das soluções favorecidas por tecnologia. Algumas empresas
                      ainda não confiam em passar sua inteligência a prestadores
                      externos de serviço e, logo após a implementação, deixam a
                      sustentação na mão de seus key users ou do departamento
                      interno de Tecnologia da Informação. Contudo, a
                      sustentação de um ERP – Sistema de Gestão Empresarial é
                      bastante complexa e a resposta a incidentes críticos fica
                      comprometida. A saída acaba sendo recorrer a um consultor
                      externo, mas encontrar um com as habilidades necessárias,
                      em janela de projeto, pode levar muito tempo.
                    </p>
                    <p>
                      Quando o profissional é localizado com urgência, ele pode
                      ficar dias levantando as informações necessárias para dar
                      início à resolução do chamado. Adotar a contratação do
                      Serviço de Gerenciamento de Aplicações remota pode
                      resolver em 16 horas o que para um profissional alocado
                      levaria 80 horas. O esforço é muito menor uma vez que
                      equipes internas de TI nem sempre têm condições de atender
                      à demanda, por motivos de quantidade de colaboradores e
                      falta de atualização sobre os recursos de tecnologia da
                      informação disponíveis no mercado. A terceirização dos
                      serviços contribui para acabar com esse problema. Quando
                      as atividades de TI são executadas por uma empresa
                      especializada, o atendimento é mais rápido — por haver uma
                      mão de obra qualificada e devidamente dimensionada para
                      suprir as necessidades disponíveis no momento de
                      criticidade.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>2. Escalabilidade da tecnologia</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      À medida em que a empresa cresce, pode aumentar também seu
                      leque de aplicações e, como citado no item anterior,
                      cresceria na mesma medida a equipe necessária para
                      atendê-lo. Para suportar tecnologias escaláveis, como
                      ERPs, que estão constantemente sendo complementadas por
                      programas satélites, buscar consultoria com um time de
                      alta senioridade e know how em diferentes setores pode ser
                      uma boa saída. Assim, sua empresa pode se preocupar apenas
                      com o desenvolvimento do seu core business e não com a
                      solução de problemas em seus sistemas.
                    </p>
                    <p>
                      A tecnologia da informação tem como um dos principais
                      objetivos fazer com que os serviços prestados por sua
                      empresa sejam mais velozes, tanto para o público interno,
                      quanto para o externo. Por isso, é fundamental que haja um
                      elevado índice de automatização dos processos. Uma forma
                      de alcançar essa meta é adotar as melhores práticas e
                      soluções inovadoras vigentes. Ter uma equipe terceirizada,
                      imersa nas mais inovadoras soluções existentes no mercado,
                      será o diferencial para seu negócio, como exemplo:
                    </p>
                    <p></p>
                    <ul style="list-style: inside">
                      <li>Computação em Nuvem;</li>
                      <li>Inteligência artificial;</li>
                      <li>Internet das coisas (IoT).</li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>3. Qualidade do ServiceDesk</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      O segredo da qualidade e agilidade de um bom serviço de
                      suporte e manutenção é adotar um programa robusto de
                      <a>ServiceDesk - Quartz</a>. Por meio desta interface, os
                      técnicos da Shinier recebem seus chamados, descobrem as
                      causas-raiz e dão início à busca por soluções. Para
                      garantir a excelência de serviço seguimos as boas práticas
                      definidas pelo
                      <a
                        href="https://www.tsoshop.co.uk/bookstore.asp?FO=1359255&ProductID=9780113316069&Action=Book"
                        target="_blank"
                      >
                        ITIL – Information Technology Infrastructure Library </a
                      >, órgão internacional regulador da infraestrutura,
                      operação e manutenção de serviços de TI.
                    </p>
                    <p>
                      Quando o cliente abre um chamado de incidente por meio do
                      <a>Quartz</a>, ele envia a dúvida, erro ou melhoria de que
                      precisa. Ele pode acessar os técnicos por variados canais:
                      o próprio HelpDesk via internet, por telefone, skype ou
                      enviar um e-mail – desde que tenha todas as informações de
                      pré-requisito para fazer o pedido. Isso cria um número de
                      chamado que imediatamente cai no atendimento nível 1:
                      equipe disponível 7 dias da semana, 24 horas por dia, em
                      inglês, português e espanhol, que checam se o chamado está
                      preenchido corretamente e direcionam para a fila do grupo
                      solucionador. Em seguida, o atendimento encaminha em até
                      cinco minutos o pedido para a equipe correta; no nível 2,
                      quando se trata de incidente, a meta de resolução é
                      baseada na prioridade do chamado. Em caso de urgência, o
                      time tem até duas horas para responder e até seis horas
                      para corrigir.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>4. Satisfação do público interno</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Proporcionar boas condições de trabalho para os
                      colaboradores é imprescindível para uma empresa inovadora
                      alcançar as metas e ser bem-avaliada pelos seus clientes.
                      Ao terceirizar os serviços de TI, atingir esses objetivos
                      fica bem mais viável. Isso porque os funcionários terão
                      sistemas e infraestrutura de redes mais adequadas para
                      executarem suas atividades profissionais. Além disso,
                      terão um atendimento diferenciado para resolverem
                      eventuais problemas relacionados à tecnologia da
                      informação.
                    </p>
                    <p>
                      É fácil perceber que a terceirização de serviços de TI é
                      um ótimo caminho para a administração de seu negócio ser
                      mais eficiente e ágil. Afinal, cada vez mais, o mercado
                      exige que a resolução das demandas seja feita com rapidez
                      e precisão. Caso esteja precisando de parceiros para usar
                      a tecnologia a favor da sua organização, entre em contato
                      com a equipe da
                      <a @click="openModal">Shinier</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[4].src"
                :alt="image[4].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Condinvest</span>
                  <hr />
                </div>
                <p>
                  A CondInvest serviços e jurídico de cobrança de condomínio nos
                  designou a oportunidade de levar a empresa ao que a de mais
                  moderno em soluções digitais, assim trabalhamos em uma
                  consultoria completa, para só então integrar as melhorias na
                  plataforma interna e um novo aplicativo para seus clientes.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";

export default {
  name: "Quartz",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Quartz - Serviço de Suporte a Sistemas",
    meta: [
      {
        name: "description",
        content:
          "Há algumas diferenças entre as modalidades de manutenção: R&D (Research & Development), na qual são atendidas as correções de bugs, P&D (Pesquisa e Desenvolvimento) - de negócio, abordagem legal e a evolução tecnológica dos produtos desenvolvidos e, por fim, o contrato complementar à manutenção, que trata a sustentação com nível de serviço destes produtos atendidos por Quartz - AMS"
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesQuartz;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
