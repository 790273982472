<template>
  <div class="col-sm-6 col-md-5">
    <div class="leads-solucoes">
      <div :id="gradient">
        <div class="box-chamada">
          <strong>Interessado nessa solução?</strong>
          <div class="paragraph">
            <p></p>

            <p>
              {{ callToAction }}
            </p>
            <p v-if="!callToAction" style="text-align: center">
              Entraremos em contato passando uma proposta comercial o mais breve
              possível!
            </p>
            <p></p>
          </div>

          <a @click="send()" class="btn btn-orange-solid">
            {{ callBtn }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import choosePlan from "../../mixins/choosePlan";
export default {
  props: ["gradient", "callToAction", "callBtn", "link", "route"],
  mixins: [choosePlan],
  methods: {
    send(message) {
      message = `Olá Marcius, estou interessado no módulo ${this.$route.name}`;
      this.sendPlan(message);
    },
  },
};
</script>
