<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="gold"
          itemProps="Home / Soluções / Gold"
          icon="icon-gold-color-48"
          title="Gold - Omnichannel"
        />

        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-7">
                <!-- title-h2 title-outline -->
                <h2 class="h2">
                  Qual a diferença entre Omnichannel e Multicanais?
                </h2>

                <div class="paragraph">
                  <p>
                    A principal diferença é que a venda realizada no multicanais
                    cada canal pode ter sua operação de forma independente.
                    Enquanto no lançamento de um produto novo no omnichannel é
                    propagado em todas as plataformas, transformando a
                    experiência do seu cliente em algo conciso com a sua marca.
                  </p>
                  <p>
                    O e-commerce é comodidade, e praticidade, esse é o serviço
                    que deve prestar aos seus clientes. Porém a venda somente
                    online não tem o impacto que lojas físicas podem
                    proporcionar, principalmente nas pequenas empresas, em que o
                    físico é responsável pela maior parcela do faturamento.
                  </p>
                  <p>
                    O Gold - Omnichannel vem solucionar as dores do pequeno,
                    médio e até grande comerciante ao oferecer uma plataforma
                    personalizada para sua marca, provendo web e app integrados
                    com sistema ERP para uma loja ou filiais.
                  </p>
                </div>
              </div>
              <CardContato
                callBtn="quero esse módulo"
                gradient="gold-gradient-card"
                callToAction="Disponibilizamos planos para você ter sua loja virtual hoje mesmo"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- class="title-h2" -->
                    <h2>
                      <strong
                        >Situação do e-commerce no mercado brasileiro</strong
                      >
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      O e-commerce brasileiro faturou 56,8% a mais nos oito
                      primeiros meses de 2020 em comparação com igual período do
                      ano passado, segundo pesquisa realizada pelo Movimento
                      Compre&Confie em parceria com a Associação Brasileira de
                      Comércio Eletrônico (ABComm).
                      <a
                        href="https://abcomm.org/noticias/faturamento-do-e-commerce-cresce-568-neste-ano-e-chega-a-r-4192-bilhoes/"
                        target="_blank"
                        >Saiba mais</a
                      >
                    </p>
                    <p>
                      O e-commerce brasileiro manterá um bom ritmo de expansão
                      em 2021. Segundo previsão da consultoria Ebit|Nielsen, as
                      vendas online devem crescer 26%, atingindo um faturamento
                      de R$ 110 bilhões, mantendo a força do setor e indicando
                      uma consolidação das lojas e dos marketplaces.
                      <a
                        href="https://canaltech.com.br/e-commerce/e-commerce-brasil-deve-crescer-26-em-2021-preve-ebit-nielsen-176430/"
                        target="_blank"
                        >Saiba mais</a
                      >
                    </p>
                    <p>
                      Além das compras online, de forma geral, o estudo detalha
                      de que forma essas vendas foram feitas. De acordo com o
                      42º Webshoppers, os varejistas de marketplaces representam
                      78% do faturamento total do e-commerce brasileiro.
                      <a
                        href="https://www.meioemensagem.com.br/home/marketing/2020/08/27/e-commerce-cresce-47-maior-alta-em-20-anos.html"
                        target="_blank"
                        >Saiba mais</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong
                        >Entenda as tecnologias utilizadas e como elas facilitam
                        os processos:
                      </strong>
                    </h2>
                    <p>
                      É necessário conhecer as principais tecnologias que estão
                      transformando a indústria e que estão fornecendo dados
                      extremamente valiosos para os negócios. Confira algumas
                      das principais tecnologias do varejo 4.0:
                    </p>
                    <p></p>
                    <ul
                      class="list-top"
                      style="list-style: inside disc; text-align: justify"
                    >
                      <li>
                        <b>Big Data:</b> O Big Data é um enorme volume de dados
                        que podem ser usados para definir padrões e tendências
                        associadas a comportamentos humanos, portanto, quando
                        utilizado no varejo, fornece as informações necessárias
                        para personalizar a experiência de compras dos
                        consumidores. Ao utilizar os dados fornecidos pelas
                        transações financeiras dos clientes, bem como endereço
                        de IP e histórico de compras, por exemplo, as empresas
                        conquistam um diferencial competitivo baseado em dados
                        reais e atualizados de seus clientes. Ao analisar os
                        dados coletados, o empreendimento pode realizar as
                        alterações nos processos que se mostram ineficientes e
                        otimizar os que estão apresentando resultados
                        satisfatórios. O Big Data auxilia os gestores nas
                        principais tomadas de decisão, realiza o correto
                        mapeamento da jornada de compras do cliente, informa a
                        melhor maneira de atingir os consumidores e indica o
                        caminho necessário para alavancar as vendas e o ROI.
                      </li>
                      <li>
                        <b> Machine Learning:</b> O Machine Learning, ou
                        aprendizado de máquina, é uma das tecnologias que mais
                        auxiliam nos processos de automação das empresas. É um
                        importante recurso da inteligência artificial que
                        possibilita que máquinas desempenhem determinada função
                        e passem a aprender com a prática e, dessa maneira,
                        aprendam a tomar melhores decisões.É a maneira de
                        computadores analisarem dados para encontrar padrões e
                        tendências que os auxiliem nas futuras tomadas de
                        decisão. Tudo isso de maneira autônoma e com o mínimo de
                        interferência humana o possível. É um sistema
                        tecnológico inteligente que, tendo como base a sua
                        própria experiência, pode, sozinha, realizar
                        modificações para melhorar os resultados esperados.
                      </li>
                    </ul>

                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2><strong> Como funciona o Omnichannel? </strong></h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <!--  -->
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      A ideia de montar uma rede integrada de vendas não é nova,
                      porém com o advento da tecnologia, as facilidades da
                      internet, e a chegada dos aplicativos para smartphones,
                      surge a exigência de que o lojista se atualize, tanto para
                      manter seus clientes, quanto para aproveitar a expansão
                      que o online pode proporcionar.
                    </p>
                    <p>
                      Com o site e o app, você trará a comodidade ao seu cliente
                      para fazer a compra onde quiser, enquanto mantém a
                      experiência de interagir com seu consumidor e conhecê-lo,
                      além de manter a pronta entrega. Isso porque os sistemas
                      estão se comunicando através da solução Gold.
                    </p>
                    <p>
                      <b>E-commerce:</b> Ao entregar a plataforma web e
                      aplicativo disponível na playstore e applestore, você
                      aumentará o alcance da sua marca, conseguindo um público
                      que até então não chegava até sua loja.
                    </p>
                    <p>
                      <b>ERP:</b> Com nosso sistema de ERP você poderá ter a
                      visão do seu fluxo de faturamento, pedidos, produtos e
                      etc. Além de conseguir visualizar tomadas estratégicas,
                      como: “onde abrir uma nova loja?”.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong> O que a solução Gold tem a oferecer: </strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      A dor de um cliente em 2018 tornou-se o remédio disponível
                      para todos:
                    </p>
                    <ul
                      style="
                        list-style: inside;
                        list-style-type: disc;
                        text-align: justify;
                      "
                    >
                      <li>
                        Gestão de produtos, estoques, categorias, filtros de
                        camadas, pacote de produtos.
                      </li>
                      <li>Integração com as plataformas de pagamento.</li>
                      <li>Integração com sistemas de transportadoras.</li>
                      <li>
                        Acompanhamento de pedido: você pode manter contato com o
                        seu cliente desde o fechamento da compra até a entrega.
                      </li>
                      <li>
                        Controle do faturamento: entrada e saída de caixa,
                        emissão de nota fiscal.
                      </li>
                      <li>
                        Disponibilidade 24 horas, 365 dias do ano, manutenção
                        programada.
                      </li>
                      <li>
                        Gestão de filiais, e tomada de decisões estratégicas.
                        Tudo na palma da sua mão.
                      </li>
                      <li>
                        Acompanhar suas vendas e obter feedback dos seus
                        clientes sobre o seu negócio.
                      </li>
                      <li>
                        Customização para sua marca, suas necessidades e muito
                        mais.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="info">
                  <h2><strong>Módulos Gold</strong></h2>
                  <figure
                    class="figure"
                    style="float: right; margin-left: 60px"
                  >
                    <img
                      v-lazy="image[3].src"
                      :alt="image[3].alt"
                      class="img-responsive img-loaded"
                    />
                  </figure>
                  <p>São:</p>
                  <p></p>
                  <ul
                    style="
                      list-style: inside;
                      list-style-type: disc;
                      text-align: justify;
                    "
                  >
                    <li>
                      Plataforma web: site para venda online, com a identidade
                      da sua marca.
                    </li>
                    <li>
                      Aplicativo: solução mobile para seus clientes e gestores.
                    </li>
                    <li>
                      ERP: gestão da(s) sua(s) loja(s), tudo integrado e
                      disponível.
                    </li>
                    <li>
                      Marketplace: integração com os principais players do
                      mercado.
                    </li>
                  </ul>
                  <p></p>
                  <p>
                    Com Gold seus clientes terão a melhor experiência na compra,
                    escolha e recompra, podendo acompanhar seus produtos,
                    novidades, promoções e muito mais, sem sair do conforto de
                    suas casas e com garantia da segurança dos seus dados e de
                    um serviço de excelência.
                  </p>
                  <p>
                    É bom para sua empresa pois permitirá seu posicionamento no
                    mercado, com o custo acessível e com uma solução completa
                    para seu negócio, e tudo em um só lugar. Apoiado em
                    tecnologia e inovação, com o que há de melhor em segurança e
                    disponibilidade. Uma solução que vale ouro!!!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->

        <!--  -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[4].src"
                :alt="image[4].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Nildo Adega</span>
                  <hr />
                </div>
                <p>
                  A loja de bebidas Nildo Adega, em busca do melhor para seus
                  clientes, expandiu seu negócio e solicitou uma de nossas
                  soluções, onde fomos responsáveis pela customização e
                  implantação do e-commerce, desenvolvimento e suporte do
                  aplicativo e ERP.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="col-case col-reversed">
              <img
                v-lazy="image[5].src"
                :alt="image[5].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Lana Oliveira Acessórios</span>
                  <hr />
                </div>
                <p>
                  A loja de semijoias Lana Oliveira tem seus produtos
                  desenvolvidos e fabricados com muito carinho e dedicação
                  visando atender a todos os públicos desde o despojado até o
                  mais glamouroso, prezando pela qualidade das peças e por um
                  bom atendimento online.Esse foi outro caso de sucesso, onde
                  trabalhamos na criação do tema customizado para para seu
                  e-commerce.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";
export default {
  name: "Gold",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Gold - Construir Loja Online com nosso Omnichannel",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Gold - Omnichannel vem solucionar as dores do pequeno, médio e até grande comerciante com uma plataforma personalizada para sua marca, web e app integrando com sistema ERP, para uma loja ou filiais. E ao usar uma solução pré-formatada agiliza e diminui os custos de implantação e manutenção."
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesGold;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
