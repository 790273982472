<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader
          imgBack="opal"
          itemProps="Home / Soluções / Opal"
          icon="icon-opal-color-48"
          title="Opal - Sistema de Gerenciamento de Armazém"
        />
        <!-- Abre introdução segmento manufatura -->
        <div class="section section-white section-top">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-7">
                <!-- title-h2 title-outline -->
                <h2 class="h2">Os benefícios da implantação de um WMS?</h2>

                <div class="paragraph">
                  <p>
                    A otimização da cadeia de suprimentos é essencial para
                    qualquer empresa. Como uma função estratégica, ela é
                    fundamental para a previsão da produção dos itens que serão
                    comercializados e também para uma boa gestão de estoque.
                  </p>
                  <p>
                    O equilíbrio na quantidade de itens armazenados para evitar
                    perdas geradas por prazos de validade, armazenamento
                    incorreto, perda de vendas devido à ausência de produtos em
                    estoque ou, por fim, a necessidade de reduzir margens de
                    lucro para realizar promoções com o objetivo de diminuir o
                    número de itens encalhados no local.
                  </p>
                  <p>
                    Dessa forma, para otimizar esse setor, é importante pensar
                    em automação para evitar erros e agilizar processos. Com a
                    implementação do Opal - WMS (Warehouse Management System) em
                    seu negócio, estará ganhando a vantagem competitiva que
                    falta para lançar seu negócio entre os principais
                    concorrentes.
                  </p>
                </div>
              </div>
              <CardContato
                :route="$route.name"
                callToAction="Otimize a gestão da sua cadeia de suprimentos com a ajuda da Shinier!"
                callBtn="quero esse módulo"
                gradient="opal-gradient-card"
              />
            </div>
          </div>
        </div>
        <!-- fecha introdução segmento manufatura -->

        <!--  -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h2>
                      <strong>
                        <a @click="$router.push({ name: 'Opal' })"
                          >Gestão de Logística | Opal- WMS</a
                        >
                      </strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Os sistemas WMS oferecem, atualmente, muito mais
                      praticidade para gestores logísticos, uma vez que podem
                      ser utilizados por meio de dispositivos móveis, como
                      tablets e smartphones. Basta estar conectado à internet
                      que todos os dados do sistema ficam armazenados na nuvem.
                      Acompanhe todo o fluxo logístico, do recebimento à
                      expedição. Controle fretes, frotas e visualize entregas em
                      tempo real. Reduza o tempo de movimentação dos produtos no
                      armazém e ganhe produtividade.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Abre beneficios segmento manufatura -->
        <div class="section section-white section-beneficios">
          <div class="container">
            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- title-h2 -->
                    <h2>
                      <strong>Como ele funciona?</strong>
                    </h2>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[0].src"
                        :alt="image[0].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Um sistema do tipo WMS - Sistema de Gerenciamento de
                      Armazém é um software destinado ao recolhimento de
                      informações importantes para o sistema de armazenamento e
                      logística. Em síntese, seu objetivo é realizar uma gestão
                      eficiente de estoque, sendo utilizado desde o recebimento
                      de produtos após a produção até o seu envio para
                      transporte.
                    </p>
                    <p>
                      Por meio de códigos de barras, Datamatrix ou sensores (
                      <a @click="$router.push({ name: 'Garnet' })"
                        >RFID ou Beacons</a
                      >), é possível registrar a movimentação dos itens que
                      entram e que saem do local de armazenamento em tempo real,
                      atualizando e fornecendo o inventário do seu negócio assim
                      que solicitado.
                    </p>
                    <p>
                      Cada entrada, movimentação e saída deve ser registrada no
                      sistema imediatamente. Dessa maneira o sistema contabiliza
                      as mudanças no inventário, acrescentando informações
                      relevantes, tais como o prazo de validade, histórico do
                      produto, lote, entre outros.
                    </p>
                    <p>
                      Assim, esse sistema pode ser acessado por dispositivos
                      móveis e redes locais, facilitando o acompanhamento pelos
                      diversos setores que necessitam dessas informações.
                      Através do Opal-WMS é possível obter essa informação
                      imediatamente, prevenindo problemas gerados por falhas de
                      comunicação.
                    </p>
                    <p>
                      Ele também se integra a sistemas ERP’s e/ou CRM’s, de
                      forma que quando a compra realizada pelo cliente é
                      registrada no sistema, uma mensagem é enviada diretamente
                      para o Opal-WMS, que processará todos os passos
                      necessários para enviar o pedido, também consolida no ERP
                      informações para emissão de notas e gestão estratégica.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <!-- class="title-h2" -->
                    <h2><strong>Quais as vantagens do Opal-WMS?</strong></h2>
                    <p>
                      Podemos perceber que um sistema do tipo WMS é essencial
                      para qualquer empresa que trabalhe com armazenagem e
                      estoque. Além disso, ela traz uma série de benefícios para
                      o negócio em curto e longo prazo, tais como os que vamos
                      apresentar a seguir.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>1. Controle operacional</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[1].src"
                        :alt="image[1].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      Por meio do sistema Opal-WMS, caso seja necessário
                      verificar o que há disponível no inventário, é possível
                      ter essa informação em tempo real, auxiliando em decisões
                      estratégicas de outros setores da empresa. Esse controle é
                      feito automaticamente, já que o sistema contabiliza as
                      informações e realiza a sincronização imediatamente.
                    </p>
                    <p>
                      Com um sistema automatizado, há uma economia de tempo em
                      diversas etapas relacionadas à logística. Por exemplo, o
                      registro da entrada de produtos no local de armazenamento
                      é feito de imediato, por meio da leitura de um código
                      Datamatrix. Contabilizando a informação no banco de dados
                      do software, evita-se o tempo gasto com preenchimento
                      manual de todas as informações, bem como diminuem-se as
                      chances de falhas humanas no processo.
                    </p>
                    <p>
                      Permitindo um efeito cascata por todas as etapas
                      operacionais, tal como remanejamento entre estoques
                      (quando há a necessidade de transferir os produtos de uma
                      unidade para outra), picking, saída para transporte,
                      verificação de prazos de validade, entre outros. Há também
                      redução de tempo para a disponibilidade de informações.
                      Sempre que é necessário, qualquer pessoa com acesso devido
                      pode verificar as informações do estoque em tempo real
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>2. Melhoria da gestão do estoque</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: left; margin-right: 60px"
                    >
                      <img
                        v-lazy="image[2].src"
                        :alt="image[2].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      O sistema Opal-WMS mostra quando há um espaço disponível
                      no estoque que pode ser ocupado por outros itens. Com isso
                      há uma melhor organização no local da armazenagem,
                      permitindo que esses espaços sejam ocupados segundo a
                      dinâmica de entradas e saídas de produtos, evitando
                      problemas com espaços desperdiçados.
                    </p>
                    <p>
                      Quando a empresa possui mais de uma locação de
                      armazenagem, o sistema auxilia na otimização do percurso
                      de perdidos. Isso porque ele aponta qual dos locais possui
                      o item solicitado e qual está mais próximo ao destino
                      final, tanto dentro de um armazém, dando um caminho ótimo
                      para o operador realizar o picking, como entre os centros
                      logísticos. Diminui-se assim o tempo de entrega e
                      agilizam-se os processos de transporte com uma melhor
                      gestão da rota, ganhando a confiança do cliente,
                      potencializando a eficiência do negócio e conquistando uma
                      melhor imagem no mercado.
                    </p>
                    <p>
                      Com a melhoria na gestão dos espaços, bem como com o
                      controle adequado do giro de estoque, é possível melhorar
                      e impulsionar o aumento do material estocado. Além disso,
                      essa melhoria é feita de forma consciente, evitando que
                      ocorra a escolha por um produto que não tenha demanda
                      constante e, com isso, haja a necessidade de realizar
                      promoções e queimas de estoque. Ou seja, ganha-se em
                      produtividade e, ao mesmo tempo, há uma gestão mais
                      inteligente e estratégica da produção e armazenagem na
                      empresa.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-beneficios block-right">
              <div class="beneficios-wrapper">
                <div class="paragraph">
                  <div class="info">
                    <h3 class="title-h2 title-outline">
                      <b>3. Movimentação horizontal do operacional</b>
                    </h3>
                    <figure
                      class="figure"
                      style="float: right; margin-left: 60px"
                    >
                      <img
                        v-lazy="image[3].src"
                        :alt="image[3].alt"
                        class="img-responsive img-loaded"
                      />
                    </figure>
                    <p>
                      A automação realizada pelo sistema WMS permite a economia
                      da mão de obra em tarefas que antes eram realizadas de
                      forma manual. Por exemplo, se anteriormente eram
                      necessários funcionários para realizar métodos de recolha
                      e localização de estoque, a partir da implementação do
                      sistema isso é feito de forma automática, permitindo a
                      realocação desse esforço para outras áreas do operacional,
                      garantindo assim maior eficiência nos processos chaves.
                    </p>
                    <p>
                      Além disso, por ser feito de forma automatizada, reduz-se
                      erros humanos durante o processo, gerando também uma
                      economia no que concerne ao investimento realizado e ao
                      tempo necessário para a execução da tarefa.
                    </p>
                    <p>
                      Uma boa gestão da cadeia de suprimentos é essencial para
                      melhorar a produtividade e potencializar o rendimento
                      mensal do seu negócio. Porém, há diversos desafios
                      envolvidos nesse setor. Entre em contato com a equipe da
                      <a>Shinier</a> para saber mais.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fecha beneficios segmento manufatura -->
        <section
          class="list list-new-destaques solucoes"
          style="padding: 0 !important"
        >
          <div
            class="container"
            style="width: 100%; padding-top: 80px; padding-bottom: 80px"
          >
            <h2 class="title1">
              <span>Nossos cases</span>
            </h2>
          </div>
          <div class="cases">
            <div class="col-case">
              <img
                v-lazy="image[4].src"
                :alt="image[4].alt"
                class="img-loaded img-col-case"
              />
              <!--  </figure> -->

              <div class="col-paragraph">
                <div class="title-wrapper">
                  <hr />
                  <span class="title-col-case">Sonda ativas </span>
                  <hr />
                </div>
                <p>
                  A Sonda Ativas é uma das principais provedoras de serviços
                  gerenciados de TI (MSP - Managed Service Provider). Empresa do
                  grupo Sonda, oferece uma plataforma completa de infraestrutura
                  e gestão de serviços para empresas que buscam alinhar a TI aos
                  negócios. A empresa nos procurou para desenvolvermos o sistema
                  de gestão dos assets, com localização nos hacks e setores
                  alocados.
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--  -->
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../../components/Banner/PageHeader";
import CardContato from "@/components/Cards/CardContato";
import scrollToTop from "../../mixins/scrollToTop";

export default {
  name: "Opal",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Opal - Tecnologia de Gestão de Estoque Automatizada",
    meta: [
      {
        name: "description",
        content:
          "A otimização da cadeia de suprimentos é essencial para qualquer empresa. O equilíbrio na quantidade de itens armazenados para evitar perdas geradas por prazos de validade, armazenamento incorreto...  Um sistema do tipo WMS - Sistema de Gerenciamento de Armazém é um software destinado ao recolhimento de informações importantes"
      }
    ]
  },
  data() {
    return {
      image: []
    };
  },
  mixins: [scrollToTop],
  created() {
    this.image = this.$store.getters.getAllImages.imagesOpal;
  },
  components: {
    PageHeader: PageHeader,
    CardContato: CardContato
  }
};
</script>
<style scoped src="./solucoes.css"></style>
