<template>
  <div>
    <div class="main-container">
      <main
        role="main"
        :style="'max-width: 1024px' ? 'overflow: hidden' : null"
      >
        <PageHeader title="SHINIER - Módulos" />

        <div class="all-soluctions container">
          <a @click="$router.push({ name: 'Gold' })">
            <div class="all-soluction icon-gold">
              <p>
                <span>Gold - Omnichannel</span> Com um Gold-Omnichannel seu
                comércio estará disponível 24 horas para seus clientes e
                integrado com os principais player do mercado
              </p>
            </div>
          </a>
          <a @click="$router.push({ name: 'Gold' })">
            <div class="all-soluction icon-diamondsoluction">
              <p>
                <span>Diamond - Marketplaces</span> Customizamos uma plataforma
                para você, com gestão de acesso, multi nível de usuários, auto
                cadastro, supervisionado ou integrado, e muito mais
              </p>
            </div>
          </a>
          <a @click="$router.push({ name: 'Ametista' })">
            <div class="all-soluction icon-ametistasoluction">
              <p>
                <span>Ametista - Education Management System</span> É o software
                de gestão escolar completo, que vai integrar todos os processos,
                aumentar a eficiência e a produtividade de sua escola
              </p>
            </div>
          </a>

          <a @click="$router.push({ name: 'Opal' })">
            <div class="all-soluction icon-opal">
              <p>
                <span>Opal - Warehouse Management System</span> É um software
                destinado ao recolhimento de informações importantes para o
                sistema de armazenamento e logística.
              </p>
            </div>
          </a>
          <a @click="$router.push({ name: 'Jade' })">
            <div class="all-soluction icon-jade">
              <p>
                <span>Jade - Live Management System</span> É um sistema
                associado a sensores com a finalidade de fazer o monitoramento
                ao vivo dos principais componentes do seu processo.
              </p>
            </div>
          </a>

          <a @click="$router.push({ name: 'Quartz' })">
            <div class="all-soluction icon-quartz">
              <p>
                <span>Quartz - Application Management Services</span> Trata-se
                de um serviço de suporte a sistemas. (R&D - Research e
                Development, P&D - Pesquisa e Desenvolvimento)
              </p>
            </div>
          </a>

          <a @click="$router.push({ name: 'Garnet' })">
            <div class="all-soluction icon-garnet">
              <p>
                <span>Garnet - Beacons</span> É um módulo de comunicação de
                IoT's e algoritmos de data science, afim de coletar dados
                críticos para análise da curva de produção
              </p>
            </div>
          </a>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/Banner/PageHeader";
import scrollToTop from "../mixins/scrollToTop";

export default {
  name: "Solucoes",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR",
    },
    title: "Todos os Módulos",
    titleTemplate: "%s | Shinier",
    meta: [
      {
        name: "description",
        content:
          "Todos as Soluções em que a Shinier desenvolveu produtos e know How para crescimento e escalabilidade, para nossos clientes.",
      },
      /* { name: "robots", content: "noindex, nofollow" } */
    ],
  },
  mixins: [scrollToTop],
  components: {
    PageHeader: PageHeader,
  },
};
</script>
